import React, { useEffect } from "react";

const HubspotContactForm = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        document.body.appendChild(script);

        script.addEventListener("load", () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: process.env.NEXT_PUBLIC_HUBSPOT_REGION,
                    portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
                    formId: process.env.NEXT_PUBLIC_HUBSPOT_FORM_ID,
                    target: "#hubspotForm",
                });
            }
        });
    }, []);

    return <div id="hubspotForm" className="mx-auto" />;
};

export default HubspotContactForm;
