import { ContactLinks, copyrightText } from "@/constants";
import HubspotContactForm from "@/components/home/HubspotContactForm";
import Image from "next/image";
import CImage from "@/components/layouts/CImage";

const Contact = () => {
    return (
        <>
            <div className='bg-[url("/home/ellipse33.svg")] bg-no-repeat bg-left-top'>
                <div className="max-w-7xl mx-auto grid items-center grid-cols-1 md:grid-cols-2 gap-4 py-20">
                    <div>
                        <div className="flex flex-col items-center md:items-start space-y-4 text-primary-500 font-bold">
                            <h2 className="text-7xl md:text-9xl">Say Hello</h2>
                            <h3 className="text-4xl font-semibold">
                                Contact us
                            </h3>
                        </div>
                    </div>
                    <div className="h-auto p-6 mt-3 bg-white shadow rounded-md">
                        <HubspotContactForm />
                    </div>
                </div>
            </div>
            <div className=' relative bg-[url("/home/footer-bg.svg")] bg-no-repeat bg-cover text-white  py-5 md:py-20'>
                <CImage
                    height="125"
                    width="245"
                    src="/home/FooterLeftEllipse.svg"
                    loading="lazy"
                    alt="FooterLeftEllipse"
                    className="absolute top-0 left-0 bottom-80 md:bottom-52"
                />
                <div className="mx-auto max-w-7xl flex flex-col-reverse items-center gap-4 md:flex-row md:justify-between">
                    <div>
                        <div className="flex mt-2">
                            <span
                                className="text-sm text-justify pl-1"
                                data-test-id="contact-us-at"
                            >
                                Follow us on
                            </span>
                        </div>
                        <div className="flex gap-2 relative">
                            {ContactLinks.socialMediaLinks.map(
                                (value, index) => {
                                    return (
                                        <a
                                            key={index}
                                            className="mt-4"
                                            href={value.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            data-test-id={value.name}
                                        >
                                            <Image
                                                src={value.icon}
                                                alt={value.name}
                                                className="w-8 mt-2"
                                                width={33}
                                                height={33}
                                                loading="lazy"
                                            />
                                        </a>
                                    );
                                }
                            )}
                        </div>

                        <p
                            className="mt-8 text-sm text-justify"
                            data-test-id="copyright"
                        >
                            &copy; {copyrightText}
                        </p>
                    </div>
                    <div>
                        <div className="flex gap-10 mt-2">
                            <div className="flex flex-col gap-2">
                                <p className="font-bold">Browse </p>
                                {ContactLinks.browseLinks.map((browse) => (
                                    <a href={browse.link} key={browse.link}>
                                        <p className="text-sm">{browse.name}</p>
                                    </a>
                                ))}
                            </div>
                            <div className="flex flex-col gap-2">
                                <p className="font-bold">Support</p>
                                {ContactLinks.supportLinks.map((support) => (
                                    <a href={support.link} key={support.link}>
                                        <p className="text-sm">
                                            {support.name}
                                        </p>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex-shrink-0 w-96 w-60 md:w-[23rem] mx-auto text-center md:mx-0 md:text-left text-white opacity-90">
                            <Image
                                src="/home/tacnique-logo-c.svg"
                                alt="logo"
                                width={366}
                                height={100}
                                loading="lazy"
                            />
                            <div className="flex flex-col space-y-3 my-2">
                                <p className="space-x-2 text-sm flex items-center text-justify">
                                    <span>
                                        <Image
                                            className="w-5 h-5"
                                            src={"/logos/phone-sm.svg"}
                                            alt="phone"
                                            width={20}
                                            height={20}
                                            loading="lazy"
                                        />
                                    </span>
                                    <a href="tel:+1 650 360 2410">
                                        +1 650 360 2410
                                    </a>
                                    <span className="text-xs">
                                        <span className="ml-2 text-xs">
                                            <Image
                                                className="w-5 h-5"
                                                src={"/logos/phone-flag-3.png"}
                                                alt="phone-flag-3"
                                                width={20}
                                                height={20}
                                                loading="lazy"
                                            />
                                        </span>
                                    </span>
                                </p>
                                <p className="space-x-2 text-sm flex items-center text-justify">
                                    <span>
                                        <Image
                                            className="w-5 h-5"
                                            src={"/logos/phone-sm.svg"}
                                            alt="phone"
                                            width={20}
                                            height={20}
                                            loading="lazy"
                                        />
                                    </span>
                                    <a href="tel:+971 58 548 1478">
                                        +971 58 548 1478
                                    </a>
                                    <span className="text-xs">
                                        <span className="text-xs">
                                            <Image
                                                className="w-5 h-5"
                                                src={"/logos/phone-flag-1.png"}
                                                alt="phone-flag-1"
                                                width={20}
                                                height={20}
                                                loading="lazy"
                                            />
                                        </span>
                                    </span>
                                </p>
                                <p className="space-x-2 text-sm flex items-center text-justify">
                                    <span>
                                        <Image
                                            className="w-5 h-5"
                                            src={"/logos/phone-sm.svg"}
                                            alt="phone"
                                            width={20}
                                            height={20}
                                            loading="lazy"
                                        />
                                    </span>
                                    <a href="tel:+91 916 756 9834">
                                        +91 916 756 9834
                                    </a>
                                    <span className="text-xs">
                                        <span className="text-xs">
                                            <Image
                                                className="w-5 h-5"
                                                src={"/logos/phone-flag-2.png"}
                                                alt="phone-flag-2"
                                                width={20}
                                                height={20}
                                                loading="lazy"
                                            />
                                        </span>
                                    </span>
                                </p>
                                <p className="space-x-2  flex items-center">
                                    <span>
                                        <Image
                                            className="w-5 h-5"
                                            src={"/logos/mail-sm.svg"}
                                            alt="mail"
                                            width={20}
                                            height={20}
                                            loading="lazy"
                                        />
                                    </span>
                                    <a
                                        href="mailto:contact@tacnique.com"
                                        className="text-sm text-justify "
                                    >
                                        contact@tacnique.com
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
